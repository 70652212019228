// Customizable Area Start
// @ts-nocheck
import * as React from 'react';
import { Nav as NavContainer, NavItem, Button } from 'reactstrap';
import RenderLink from './RenderLink';
import './assets/css/style.css';
import withToast from "../HOC/withSnackBar.Web";
import { getStorageData } from "../../../../framework/src/Utilities";
import NavController from './NavController.web'

import { ReactComponent as ServiceIcon } from './assets/room-service-bring-plate.svg';
import { ReactComponent as CategoriesIcon } from './assets/layout-corners-dashboard-1.svg';
import { ReactComponent as Marketing } from "./assets/advertising-megaphone.svg"
export const configJSON = require('./config')
import  Image   from '../Nav/assets/groupIcon.svg';
const baseURL = require("../../../../framework/src/config.js").baseURL;

// Customizable Area End
class NavComponent extends NavController {
    // Customizable Area Start
    constructor(props: any) {
        super(props);
        // @ts-ignore
        this.state = {
            // @ts-ignore
            adminuser: null,
            loading: true,
            links: [
                {
                    id: 'home',
                    label: 'Home',
                    iconName: 'icon-union',
                    link: '/Home'
                },
                {
                    id: "orders",
                    label: "Orders",
                    link: "/OrderMangementList",
                    iconName: "icon-orders",
                },
                {
                    id: "categories",
                    label: "Categories",
                    link: "/CategoriessubcategoriesWeb",
                    icon: CategoriesIcon,
                },
                {
                    id: "services",
                    label: "Services",
                    link: "/ServicesManagement",
                    icon: ServiceIcon,
                },
                {
                    id: "customers",
                    label: "Customers",
                    link: "/CustomerAdminList",
                    iconName: "icon-customer",
                },
                window.location.href.includes("dev") || window.location.href.includes("stage") ?
                {
                    id: 'website-email',
                    label: 'Website & emails',
                    iconName: 'icon-website',
                    subLinks: [
                        {
                            id: "dashboard",
                            label: "Branding",
                            link: "/DashboardAdmin",
                        },
                        {
                            id: 'email setting',
                            label: 'Email Templates',
                            link: '/AdminEmailTemplates'
                        },
                        {
                            id: 'static',
                            label: 'Static Pages',
                            link: '/AdminStaticPage'
                        },
                        {
                            id: 'image',
                            label: 'Image Catalogue',
                            link: '/AdminImageCatalogue'
                        },
                        {
                            id: 'coustomer',
                            label: 'Customer Feedback',
                            link: '/AdminCustomerFeedback'
                        },
                        {
                            id: "gallery",
                            label: "Gallery",
                            link: "/Gallery",
                        },
                    ]
                } :
                {
                    id: 'website-email',
                    label: 'Website & emails',
                    iconName: 'icon-website',
                    subLinks: [
                        {
                            id: "dashboard",
                            label: "Branding",
                            link: "/DashboardAdmin",
                        },
                        {
                            id: 'email setting',
                            label: 'Email Templates',
                            link: '/AdminEmailTemplates'
                        },
                        {
                            id: 'static',
                            label: 'Static Pages',
                            link: '/AdminStaticPage'
                        },
                        {
                            id: 'image',
                            label: 'Image Catalogue',
                            link: '/AdminImageCatalogue'
                        },
                        {
                            id: 'coustomer',
                            label: 'Customer Feedback',
                            link: '/AdminCustomerFeedback'
                        },
                        {
                            id: "gallery",
                            label: "Gallery",
                            link: "/Gallery",
                        },
                    ]
                },
                {
                    id: 'business',
                    label: 'Business Settings',
                    iconName: 'icon-settings',
                    subLinks: [
                        {
                            id: "common-settings",
                            label: "Common Settings",
                            link: "/CommonSettingsAdmin",
                        },
                        {
                            id: "staff",
                            label: "Staff",
                            link: "/AdminStaff",
                        },
                        {
                            id: 'store',
                            label: 'Store details',
                            link: '/AdminStoreDetails'
                        },  
                        {
                            id: "tax",
                            label: "Taxes",
                            link: "/AdminTaxes"
                        },
                        {
                            id: 'paymentIntegration',
                            label: 'Payment',
                            link: '/AdminPaymentIntegration'
                        },
                        {
                            id: 'mobile_app',
                            label: 'Mobile App',
                            link: '/AdminMobileApp'
                        },
                    ]
                },
                    {
                        id: "marketing",
                        label: "Marketing",
                        icon: Marketing,
                        subLinks: [
                            {
                                id: "seo",
                                label: "SEO",
                                link: "/seo-checklist"
                            },
                            {
                                id: "lead generation",
                                label: "Lead Generation",
                                link: "/marketing"
                            },
                            {
                                id: "promotions",
                                label: "Promotions",
                                link: "/ShareStoreTab"
                            }
                        ]
                    },
                {
                    id: 'account',
                    label: 'Account',
                    iconName: 'icon-account',
                    subLinks: [
                        {
                            id: 'profile',
                            label: 'Profile',
                            iconName: 'icon-user',
                            link: '/sercivesspecificsetting'
                        },
                    ]
                }
            ],
            tierType: "",
        }
    }
    // Customizable Area End

    async componentDidMount() {
        // Customizable Area Start
        const token = await getStorageData("admintoken");
        const adminUser = await getStorageData("adminuser");
        if (token && adminUser) {
            this.setState({
                adminuser: JSON.parse(adminUser) || {},
                loading: false
            })
        }
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        // @ts-ignore
        const adminuser = this.state.adminuser;
        const permissions = adminuser?.data?.attributes?.permissions;
        const permissionLinks = (data: any) => {
            if (data?.id && data?.subLinks) {
                const value1 = [];
                data?.subLinks?.map((values: any) => permissions?.find(permission => {
                    if (permission === values?.id) {
                        value1?.push(values)
                        return values
                    }
                }))
                data.subLinks = value1;
                return data?.subLinks?.length ? data : undefined;
            }
            if (data) {
                const value2 = [];
                permissions?.find(permission => {
                    if (permission === data?.id) {
                        value2?.push(data)
                        return data
                    }
                })
                return value2[0];
            }

        }
        // Customizable Area End
        // @ts-ignore
        return (
            // Customizable Area Start
            <NavContainer
                className='admin-nav'
                tabs
                vertical
            >
                {permissions && this.state?.links.length &&
                    this.state?.links.map((data: any, index: number) => (
                        permissions.includes("all")
                            ? <RenderLink data={data} tierType={this.state.tierType} />
                            : (index === 0 && permissions.includes("order")) ? <RenderLink data={this.state.links[0]} tierType={this.state.tierType} />
                                : permissionLinks(data) !== undefined
                                    ? <RenderLink data={permissionLinks(data)} tierType={this.state.tierType} /> : ''
                    ))
                         }
  <a href="/" target='_blank'>
  <Button
          variant="contained"
          style={{
            color: "#3C3E49",
            width: "188px",
            height: "40px",
            top: "548px",
            left: "24px",
            fontFamily: "Rubik",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "20px",
            border: "1px solid #DFE0E6",
            borderRadius: "3px 3px 3px 3px",
            border: "1px solid #DFE0E6",
            background: "#FFFFFF",
          }}
         // onClick={()=>this.handlePreviewClick()}
        >
          <img src={Image} alt="icon" style={{ width: "16px",height: "16px",
      top: "560px",
      left: "68px",
      padding: "3.5px 0.5px",
      marginRight: "8px" }} />
          {configJSON.Previewstore}
  </Button>
  </a>
      
            </NavContainer>
            // Customizable Area End
        );

    }


}
// Customizable Area Start
export const Nav = withToast(NavComponent);
// Customizable Area End