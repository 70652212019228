// Customizable Area Start
import React, { Component, Suspense, lazy } from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"
const EmailAccountLogin = lazy(() => import("../../EmailAccountLogin/src/Login.web"));
const EmailAccountForgotPassword = lazy(() => import("../../EmailAccountForgotPassword/src/ForgotPassword.web"));
const AdminHome = lazy(() => import("../../AdminHome/src/AdminHome.web"));
const AdminEmailTemplates = lazy(() => import("../../AdminEmailTemplates/src/EmailTemplates.web"));
const AdminEmailTemplatesCreate = lazy(() => import("../../AdminEmailTemplates/src/EmailTemplatesCreate.web"));
const AdminPaymentIntegration = lazy(() => import("../../AdminPaymentIntegration/src/Payment.web"));
const AdminMobileApp = lazy(() => import("../../AdminMobileApp/src/MobileApp.web"));
const AdminStaticPage = lazy(() => import("../../AdminStaticPage/src/StaticPageTable.web"));
const AdminStaticPageCreate = lazy(() => import("../../AdminStaticPage/src/CreateStaticPage.web"));
const AdminStoreDetails = lazy(() => import("../../AdminStoreDetails/src/StoreDetails.web"));
const AdminAccount = lazy(() => import("../../AdminAccount/src/AccountDetails.web"));
const AdminCustomerFeedback = lazy(() => import("../../AdminCustomerFeedback/src/CustomerFeedback.web"));
const AdminCustomerFeedbackCreate = lazy(() => import("../../AdminCustomerFeedback/src/CustomerFeedbackCreate.web"));
const AdminImageCatalogue = lazy(() => import("../../adminimagecatalogue/src/ImageCatalogue.web"));
const AdminImageCatalogueBulkUpload = lazy(() => import("../../adminimagecataloguebulkupload/src/ImageCatalogueBulkUpload.web"));
const AdminEditImage = lazy(() => import("../../admineditimage/src/EditImage.web"));
const AdminContact = lazy(() => import("../../contactus/src/AdminContact.web"));
const CategoriessubcategoriesWeb = lazy(() => import("../../categoriessubcategories/src/Categoriessubcategories.web"));
const GalleryGrid = lazy(() => import("../../photolibrary/src/GalleryGrid.web"));
const GalleryLists = lazy(() => import("../../photolibrary/src/GalleryLists.web"));
const GalleryView = lazy(() => import("../../photolibrary/src/GalleryView.web"));
const OrderMangementList = lazy(() => import("../../ordermanagement/src/OrderManagementList.web"));
const OrderManagementDetails = lazy(() => import("../../ordermanagement/src/OrderManagementDetails.web"));
const CommonSettingsAdminWrapper = lazy(() => import("../../commonSettingsAdmin/src/CommonSettingsAdminWrapper.web"));
const CustomerAdminList = lazy(() => import("../../customerAdmin/src/AdminCustomer.web"));
const ServicesListWrapper = lazy(() => import("../../servicemanagementAdmin/src/ServicesListWrapper.web"));
const ServiceAdminWrapper = lazy(() => import("../../servicemanagementAdmin/src/ServiceWrapper.web"));
const ImageUpload = lazy(() => import("../../photolibrary/src/ImageUpload.web"));
const ViewCustomer = lazy(() => import("../../customerAdmin/src/ViewCustomer.web"));
const AddStaffWrapper = lazy(() => import("../../accountgroups/src/addStaffWrapper.web"));
const AdminStaffList = lazy(() => import("../../accountgroups/src/AdminStaffList.web"));
const PromoCode = lazy(() => import("../../AdminPromoCode/src/PromoCode.web"));
const PromoCodeCreate = lazy(() => import("../../AdminPromoCode/src/PromoCodeCreate.web"));
import Branding from "../../AdminWebsiteBuilder/src/Branding.web";
import Servicespecificsettingsadmin2 from "../../servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2.web";
const ShareStoreTabWeb = lazy(() => import("../../AdminPromoCode/src/ShareStoreTab.web"));
const SiteMapsWeb = lazy(() => import("../../searchengineoptimisationseo/src/SiteMaps.web"));
import { SEOWithWrapper } from "../../searchengineoptimisationseo/src/Searchengineoptimisationseo.web";
import { CircularProgress } from "@material-ui/core";
const SeoLandingPage = lazy(() => import("../../searchengineoptimisationseo/src/SeoLandingPage.web"));
const ConfigureSeo = lazy(() => import("../../searchengineoptimisationseo/src/ConfigureSeo.web"));
const ConfigureSeoEdit = lazy(() => import("../../searchengineoptimisationseo/src/ConfigureSeoEdit.web"));
const PopUpFormTable = lazy(() => import("../../AdminMarketing/src/PopUpForms/PopUpFormTable.web"));
const PopUpForm = lazy(() => import("../../AdminMarketing/src/PopUpForms/PopUpForms.web"));
const ResponseTable = lazy(() => import("../../AdminMarketing/src/PopUpForms/ResponseTable.web"));
import CalendarViewList from "../../ordermanagement/src/CalendarView.web";
const CreateBooking = lazy(() => import("../../ordermanagement/src/CreateBooking.web"));
const AdminTaxes = lazy(() => import("../../../blocks/admintaxes/src/Taxes.web"));

class WebRoutes extends Component {
  render() {
    return (
      <BrowserRouter basename="/admin">
        <Suspense
          fallback={
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <CircularProgress size={40} />
            </div>
          }
        >
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/Home" />} />
            <Route exact path="/Home" component={AdminHome} />
            <Route exact path="/EmailAccountLogin" component={EmailAccountLogin} />
            <Route
              exact
              path="/EmailAccountForgotPassword"
              component={EmailAccountForgotPassword}
            />
            <Route
              exact
              path="/AdminEmailTemplates"
              component={AdminEmailTemplates}
            />
            <Route
              //exact
              path="/AdminEmailTemplatesCreate/:id"
              component={AdminEmailTemplatesCreate}
            />
            <Route exact path="/AdminStaticPage" component={AdminStaticPage} />
            <Route
              exact
              path="/AdminStaticPageCreate/:id?"
              component={AdminStaticPageCreate}
            />
            <Route
              exact
              path="/AdminImageCatalogue"
              component={AdminImageCatalogue}
            />
            <Route
              exact
              path="/AdminImageCatalogueBulkUpload"
              component={AdminImageCatalogueBulkUpload}
            />
            <Route exact path="/AdminEditImage/:id" component={AdminEditImage} />

            <Route
              exact
              path="/AdminPaymentIntegration"
              component={AdminPaymentIntegration}
            />
            <Route exact path="/AdminMobileApp" component={AdminMobileApp} />
            <Route
              exact
              path="/AdminCustomerFeedback"
              component={AdminCustomerFeedback}
            />
            <Route
              exact
              path="/AdminCustomerFeedbackCreate/:id?"
              component={AdminCustomerFeedbackCreate}
            />

            <Route exact path="/AdminStoreDetails" component={AdminStoreDetails} />

            <Route exact path="/AdminAccount" component={AdminAccount} />
            <Route exact path="/sercivesspecificsetting" component={withLayout(Servicespecificsettingsadmin2)} />

            <Route exact path="/CategoriessubcategoriesWeb" component={CategoriessubcategoriesWeb} />

            <Route exact path="/OrderMangementList" component={OrderMangementList} />
            <Route exact path="/OrderMangementList/OrderManagementDetails" component={OrderManagementDetails} />
            <Route exact path="/CalendarViewList" component={withLayout(CalendarViewList)} />

            <Route exact path="/CommonSettingsAdmin" component={CommonSettingsAdminWrapper} />

            <Route exact path="/DashboardAdmin" component={Branding} />

            <Route exact path="/AdminStaff" component={AdminStaffList} />
            <Route exact path="/AdminStaffCreate" component={AddStaffWrapper} />
            <Route exact path="/AdminStaffCreate/:id" component={AddStaffWrapper} />

            <Route exact path="/AdminPromoCode" component={PromoCode} />
            <Route exact path="/AdminPromoCodeCreate" component={PromoCodeCreate} />
            <Route exact path="/AdminPromoCodeCreate/:id" component={PromoCodeCreate} />

            <Route exact path="/CustomerAdminList" component={CustomerAdminList} />
            <Route exact path="/CustomerAdminList/AdminContact" component={AdminContact} />
            <Route exact path="/CustomerAdminList/:id" component={ViewCustomer} />

            <Route exact path="/ServicesManagement" component={ServicesListWrapper} />
            <Route exact path="/ServicesManagement" component={ServicesListWrapper} />
            <Route exact path="/ServicesManagement/ServiceManagement" component={ServiceAdminWrapper} />
            <Route exact path="/ServicesManagement/ServiceManagement/:id" component={ServiceAdminWrapper} />

            <Route exact path="/Gallery" render={() => <Redirect to="/Gallery/Grid" />} />
            <Route exact path="/Gallery/Grid" component={GalleryGrid} />
            <Route exact path="/Gallery/ImageUpload" component={ImageUpload} />
            <Route exact path="/Gallery/List" component={GalleryLists} />
            <Route exact path="/Gallery/GalleryView" component={GalleryView} />
            <Route exact path="/sitemap" component={SiteMapsWeb} />
            <Route exact path="/AdminSEO" component={withLayout(SEOWithWrapper)} />
            <Route exact path="/seo-checklist" component={withLayout(SeoLandingPage)} />
            <Route exact path="/configure-seo" component={ConfigureSeo} />
            <Route exact path="/configure-seo/:id" component={ConfigureSeoEdit} />

            <Route exact path="/ShareStoreTab" component={ShareStoreTabWeb} />
            <Route exact path="/marketing" component={PopUpFormTable} />
            <Route exact path="/marketing/response/:id" component={ResponseTable} />
            <Route exact path="/marketing/lead-generation/:id?" component={PopUpForm} />
            <Route
              exact
              path="/CreateBooking"
              component={withLayout(CreateBooking)}
            />
            <Route exact path="/AdminTaxes" component={AdminTaxes} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default WebRoutes;
// Customizable Area End
