import _refiner from 'refiner-js';
_refiner('setProject', '7fde1300-2d40-11ef-a995-118baae31466');  

export const defineUserRefiner = ({
    id, email, name
}: {
    id?: string |number;
    email?: string;
    name?: string
}) =>{
    _refiner('identifyUser', {
        id, // Replace with your user ID
        email, // Replace with user Email
        name, // Replace with user name
    });
}

export const showFormCancelCTARefiner = () => {
    _refiner("showForm", "9e434e20-2d40-11ef-8e4e-2d557e3017ce");
}

export const showFormCSATRefiner = () => {
    _refiner("showForm", "a8c44980-68ef-11ef-b905-87033e898b04");
}
