// Customizable Area Start

export const resizeImage = (base64Image: string): Promise<Record<string, string>> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = base64Image;
  
      const sizes = [
        { width: 768, height: 768, key: "desktop_banner_variant_768" },
        { width: 1280, height: 1280, key: "desktop_banner_variant_1280" },
        { width: 1920, height: 1920, key: "desktop_banner_variant_1920" },
        { width: 360, height: 360, key: "mobile_banner_variant_360" },
        { width: 414, height: 414, key: "mobile_banner_variant_414" },
        { width: 601, height: 601, key: "mobile_banner_variant_601" }
      ];
  
      img.onload = () => {
        const resizedImages: Record<string, string> = {};
        sizes.forEach(({ width, height, key }) => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
  
          if (ctx) {
            canvas.width = width;
            canvas.height = height;
  
            ctx.drawImage(img, 0, 0, width, height);
  
            resizedImages[key] = canvas.toDataURL('image/webp');
          }
        });
  
        resolve(resizedImages);
      };
  
      img.onerror = () => {
        reject(new Error('Failed to load the image'));
      };
    });
  };
  // Customizable Area End