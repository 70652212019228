export const backImage = require("../assets/image_arrow.png");
export const pickDotImage = require("../assets/image_circle.png");
export const checkedImage = require("../assets/image_icon.png");
export const siteMapsImage = require("../assets/siteInfo.png");
export const infoImage = require("../assets/info(1).png");
export const configueSeoImage = require("../assets/configSeo.png");
export const roundImage = require("../assets/view_circle.png");
export const leftArrowImage = require("../assets/image_back.png");
export const editIconImage = require("../assets/edit_icon.svg");
export const tooltipImage = require("../assets/image_tooltip.png");
export const tooltipImageSvg = require("../assets/image_icon.svg");
export const aiStarImage = require("../assets/star.svg");
export const checkedIcon = require("../assets/checkbox_.svg");
export const closeIcon = require("../assets/close_24px.svg");
export const plusIcon = require("../assets/plus_icon.svg");